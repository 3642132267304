export const primaryFont = {
  light:
    "'ProximaNovaLight', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  regular:
    "'ProximaNovaRegular', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  semiBold:
    "'ProximaNovaSemiBold', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  bold:
    "'ProximaNovaBold', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  extraBold:
    "'ProximaNovaExtraBold', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  black:
    "'ProximaNovaBlack', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
};

export const secondaryFont = {
  light:
    "'MuliLight', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  medium:
    "'MuliMedium', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  regular:
    "'MuliRegular', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  semiBold:
    "'MuliSemiBold', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  bold:
    "'MuliBold', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  extraBold:
    "'MuliExtraBold', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
  black:
    "'MuliBlack', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
};

export const scriptFont = {
  regular:
    "'WordyDivaW05Regular', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
};

export const lightFont = secondaryFont.regular;
export const boldFont = primaryFont.bold;
export const semiBoldFont = primaryFont.semiBold;
export const headerFont = primaryFont.regular;
export const bodyFont = secondaryFont.regular;
export const footerFont = secondaryFont.regular;
export const signatureFont = scriptFont.regular;

export function toRem(px: number) {
  return `${px / 16}rem`;
}

export function lineClamp(lines: number) {
  return `
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `;
}

const typography = {
  primaryFont,
  secondaryFont,
  signatureFont,
  lightFont,
  boldFont,
  semiBoldFont,
  headerFont,
  bodyFont,
  footerFont,
  toRem,
  lineClamp,
} as const;

export default typography;
